import Cookies from 'js-cookie';
import 'jquery.cookie';
import CryptoJS from 'crypto-js'
import * as status from 'components/login/status';
import axios from 'axios';
import qs from 'qs';
import ArcGIS from 'lib/arcgis/index';
var keyStr = "ABCDEFGHIJKLMNOP" + "QRSTUVWXYZabcdef" + "ghijklmnopqrstuv"  
            + "wxyz0123456789+/" + "=";
import smCrypto from 'sm-crypto'


function encode64(input) {  
    var output = "";  
    var chr1, chr2, chr3 = "";  
    var enc1, enc2, enc3, enc4 = "";  
    var i = 0;  
    do {  
        chr1 = input.charCodeAt(i++);  
        chr2 = input.charCodeAt(i++);  
        chr3 = input.charCodeAt(i++);  
        enc1 = chr1 >> 2;  
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);  
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);  
        enc4 = chr3 & 63;  
        if (isNaN(chr2)) {  
            enc3 = enc4 = 64;  
        } else if (isNaN(chr3)) {  
            enc4 = 64;  
        }  
        output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)  
                + keyStr.charAt(enc3) + keyStr.charAt(enc4);  
        chr1 = chr2 = chr3 = "";  
        enc1 = enc2 = enc3 = enc4 = "";  
        } while (i < input.length);  
  
        return output;  
}
    
function encrypt(word) {
    var key = CryptoJS.enc.Utf8.parse("Tdtglpt@202303!#");
    var srcs = CryptoJS.enc.Utf8.parse(word);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
}

// 加密
function SM2Encryptiong(pwd) {
    console.log('接收=', pwd);
    // const keyPair = smCrypto.sm2.generateKeyPairHex();  
    const publicKey = sessionStorage.getItem('pubKey')
    const privateKey = sessionStorage.getItem('pubKey')
    return '04' + smCrypto.sm2.doEncrypt(pwd, publicKey, 1)
}

//登录
function login(username, password, captcha) {
    // console.log('进入=');
    if (!username || !password) {
        return Promise.reject("用户名或密码不能为空");
    }
    // username =  encrypt(username);
    // password =  encrypt(password)
    username = SM2Encryptiong(username)
    password = SM2Encryptiong(password)

 
    return axios.post(config.urls.login, qs.stringify({
       username,
       password,
       captcha
    })).then(function (result) {
        result = result.data;
        let testToken = {
            code: 200,
            data: {
                username: '测试',
                fullname: '系统管理员',
            },
            token: 'Bearer ly666'
        }
        // return addCookies(testToken.data, testToken.token);
        if (result.code === 200) {
            return addCookies(result.data, result.token);
        } else {
            return Promise.reject(result.message);
        }
    });
}

const firstDomain='.'+document.domain.split('.').slice(1).join('.');


function logout() {
    removeCookies();
    window.location.href = config.indexPage;
}

function addCookies(loginresult, token) {
    addTokenCookie('onemaptoken', loginresult);

    // 添加请求头token
    Cookies.set('onemap_token', token, {
        expires: 0.01,
        path:config.projectName
    })
    //添加用户名称信息
    Cookies.set('onemap_username', loginresult.username, {
        expires: 0.01,
        path:config.projectName
    });
    //添加用户全称信息
    Cookies.set('onemap_fullname', loginresult.fullname, {
        expires: 0.01,
        path:config.projectName
    });
	//添加用户id
    Cookies.set('onemap_userid', loginresult.userid, {
        expires: 0.01,
        path:config.projectName
    });
    //添加用户角色信息
    Cookies.set('onemap_usertype', loginresult.usertype, {
        expires: 0.01,
        path:config.projectName
    });
		
       addTokenCookie('agstoken', loginresult.agsToken,"/");

       if (loginresult.portalToken) {
           const portal = new ArcGIS({
               token: loginresult.portalToken.token,
               domain: window.location.host + "/arcgis"
           });
           return portal.request({
               url: 'portals/self',
               form: {
                   culture: 'zh-cn',
                   f: 'json'
               },
               post: false
           }).then((selfInfo) => {
               addEsriAuth(selfInfo,loginresult.portalToken.token,7);
               return Promise.resolve(loginresult);
           }).catch((error) => {
               console.error(error);
               return Promise.reject(error);
           })
       }
       else{
           return Promise.resolve(loginresult);
       }

    
}


function addTokenCookie(key, tokenInfo,path) {
    if (!key || !tokenInfo)
        return;
    var token = tokenInfo.token;
    var timeStamp = tokenInfo.timeStamp;
    var expire = null;
    if (timeStamp && timeStamp != '') {
        try {
            expire = null;
        } catch (error) {
            expire = null;
        }
    }
    if (!expire) {
        expire = 0.01;
    }
    if(!path){
        path=config.projectName;
    }
    Cookies.set(key, token, {
        expires: expire,
        path: path
    });
}

function addEsriAuth(selfInfo,portalToken,expire){
	
    var esri_auth_cookie_obj = {
        portalApp: true,
        email: selfInfo.user.username,
        token: portalToken,
        region: null,
        culture: "zh-cn",
        expires: Date.now() + 1 * 1 * 60 * 60,
        allSSL: selfInfo.allSSL,
        accountId: selfInfo.user.orgId,
        role: selfInfo.user.role
    }
    const esri_auth_cookie_str = JSON.stringify(esri_auth_cookie_obj);
    Cookies.set('esri_auth',esri_auth_cookie_str,{
        path:'/',expires:expire
    });
}

function removeCookies() {
    const defaultPath={path:config.projectName};
    
    Cookies.remove('onemaptoken',defaultPath);
    Cookies.remove('onemap_username',defaultPath);
    Cookies.remove('onemap_fullname',defaultPath);
    Cookies.remove('onemap_usertype',defaultPath);
    
    // Cookies.remove('esri_auth','/');
    // Cookies.remove('iPlanetDirectoryPro',{domain:firstDomain});
    // Cookies.remove('agstoken', { path: '/' });
}


function check() {
    //如果有onemaptoken证明已经登录
    return status.default.getOneMapToken() ? status.default.getUserFullname() : undefined;
}

//判断是否为管理员
function isManager() {
    var usertype = status.default.getUserType();
    if (usertype === '2') {
   // if (usertype === '3'||usertype === '2'||usertype=='1') {
        return true;
    }
    return false;
}

function isManagerLogin() {
    var usertype = status.default.getUserType();
    if (usertype === '2'||usertype === '3'||usertype=='4') {
        return true;
    }
    return false;
}

function isPortalUser() {
    if (!status.default.getPortalToken()) {
        return false;
    }
    return true;
}

function token() {
    if (check()) {
        return status.default.getOneMapToken();
    }
}

function getUserName() {
    return status.default.getUserName();
}

function getFullName() {
    return status.default.getUserFullname();
}


function getUserId(){
    return status.default.getUserId();
}

function jumpToLoginPage() {
    var currentUrl = location.href;
    Cookies.set('page-afterLogin', currentUrl);
    window.location.href = config.loginPage;
}

export {
    login,
    logout,
    check,
    isManager,
    isManagerLogin,
    token,
    jumpToLoginPage,
    isPortalUser,
    getFullName,
    getUserName,
    getUserId,
    addCookies,
    encode64,
    SM2Encryptiong
}
