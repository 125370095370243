import ArcGIS from 'lib/arcgis/index';
import status from '../login/status';
import config from 'config';

const token=status.getPortalToken();
let client=null;
const projectName=config.projectName;

if(token){
    client= new ArcGIS(
        {
            token:token,
            domain:`${window.location.host}/arcgis`
        })
}
else{
    client= new ArcGIS(
        {
            domain:`${window.location.host}/arcgis`
        })
}

export default client;