import headerTemplate from "./header.ejs";
import * as login from 'components/login/login';
import swal from 'sweetalert';
import status from 'components/login/status';
import axios from 'axios';
import menuUserMapping from './menu-user-mapping';

//merge
import './merge/css/index.css'
import './merge/css/nav.css'

// 渲染用户信息
function renderUserinfo() {

	var username = login.check();
   if (username) {
	   $("#login_entry").hide();
	   $(".userinfo_ul").toggleClass("dn");
	   $(".login_ul").toggleClass("dn");
	   $("#username_label")[0].innerHTML = login.getUserName();
	   console.log($("#username label")[0])
   } else {
	   $("#login_entry").show();
	   $("#dropDown").hide();
	   $("#loginBtn").on('click', (event) => {
		   event.preventDefault();
		   login.jumpToLoginPage();
	   })
   }
}
	//注销用户
	function logout() {
		var saConfig = {
			title: "是否确定退出？",
			// text:"退出登录后您将以游客身份访问本网站。",
			buttons: {
				confirm: {
					text: "确定",
					closeModal: true
				},
				cancel: {
					text: "取消",
					visible: true,
					closeModal: true
				}
			}
		};
		swal(saConfig).then((isLogout) => {
			//登出
			if (isLogout) {
				axios({
			        method:'post',
			        headers:{username:login.encode64(login.getUserName()), userid:login.encode64(login.getUserId())},
			        url:config.urls.login_out,
			    }).then(function (result) {
			        if(result.data.code === 200){
			        	login.logout();
						renderUserinfo();
			        }
			    });
		  	}
		})
	}
	/**
	 * 根据用户的级别获取菜单
	 *
	 * @returns
	 */
	function getMenu() {
		const userType = status.getUserType();
		const menuFile = menuUserMapping[1];

		return axios.get('../static/menu/' + menuFile).then((result) => {
			let menu = result.data;
			return menu;
		})
    }
    
	export function renderHeader() {
        var isPortalUser = login.isPortalUser();
		getMenu().then((menu) => {
			var smallmenu = menu.slice(0, 5);
			menu = menu.map((menuItem) => {
				if (menuItem.name === 'digitalmap') {
					menuItem.children.map((child) => {
						if (child.name === 'geocoding') {
							var geoCodingUrl = window.config.geocodingPage;
							if (login.check()) {
								geoCodingUrl = geoCodingUrl + '?token=' + login.token();
							}
							child.url = geoCodingUrl
						} else {
							geoCodingUrl = config.loginPage;
						}
						return child;
					})
				}
				return menuItem;
            });
            
            axios.get(config.ipurl+'pskj/api/addressinterflow/query/getAllAddInterFlowList').then(e => {
                if(e.data.code == "SUCCESS"){
                    let listMenu = e.data.data;
                    let htmlText = "";
                    listMenu.map((city) =>{
                        if(city.parentId != 0){
                            htmlText +="<ul style='height:40px;display:none;position:relative;' id='childCity'><li style='width: 100%;text-align: center;line-height: 30px;'><a href='" + city.interflowUrl + "'target = '_blank' style='font-size: 0.14rem;'>" + city.addressName + "</a></li></ul></li>";
                        }else{
                        	if(city.interflowId == 6){
	                            htmlText +="<li class='hover-threeMenu'><a href='" + city.interflowUrl + "' target = '_blank'>" + city.addressName + "</a>";
	                        }else{
	                            htmlText +="<li><a href='" + city.interflowUrl + "' target = '_blank'>" + city.addressName + "</a></li>";
	                        }
                        }
                        
                    });
                    document.getElementById("provinceCity").innerHTML = htmlText;
                    $(".hover-threeMenu").mouseover( e=>{   
                        $("#childCity").css("display","block");
                    });
                    $(".hover-threeMenu").mouseout( e=>{   
                        $("#childCity").css("display","none");
                    })
                }else{
                    alert("市县互通列表请求失败！");
                }
            }).catch(e =>{
                console.log(e);
            })

			const html = headerTemplate({
				menu: menu,
				smallmenu: smallmenu,
				usernav: config.usernav(isPortalUser, config.userLevel),
				loginUrl: config.loginPage,
				MyCenter:config.mycenterPage
            });
			const container = document.createElement("div");
			container.innerHTML = html;
			document.getElementById("header").appendChild(container);
			document.getElementById("logoutBtn").addEventListener('click', (e) => {
			    logout();
			});
			renderUserinfo();
			InitEvents();
			return container;
		}).then(() => {
			// showWeather();
		})
	}

	function InitEvents() {
		var fullmenu = config.menu;
		//小菜单的起点
		var smallMenuStart = 0;
		var changeLeftBtn = document.getElementById('menu-to-right');
		// changeLeftBtn.onclick = (event) => {
		//     //菜单整体右移
		//     smallMenuStart++;
		//     changeSmallMenu(smallMenuStart, fullmenu);
		// }
		// var changeRightBtn = document.getElementById('menu-to-left');
		// changeRightBtn.onclick = (event) => {
		//     //菜单整体左移
		//     smallMenuStart--;
		//     changeSmallMenu(smallMenuStart, fullmenu);
		// }
	}


	function changeSmallMenu(smallMenuStart, fullmenu) {
		var start = smallMenuStart;
		var end = smallMenuStart + 5;
		var smallmenuNew = fullmenu.slice(start, end);
		var menuItems = document.getElementsByClassName('small-menu-item');
		for (var i = 0; i < 5; i++) {
			var menuItem = menuItems[i];
			menuItem.innerHTML = smallmenuNew[i].display;
			menuItem.href = smallmenuNew[i].url;
		}
		if (start > 0) {
			document.getElementById('menu-to-left').classList.add('useful');
		} else {
			document.getElementById('menu-to-left').classList.remove('useful');
		}
		if (start + 5 >= fullmenu.length) {
			document.getElementById('menu-to-right').classList.remove('useful');
		} else {
			document.getElementById('menu-to-right').classList.add('useful');
		}
	}








// ##############################################

/**
 * 驼峰命名<=中横线命名
 * @param {*} name 
 */
const SPECIAL_CHARS_REGEXP = /([\:\-\_]+(.))/g;
const MOZ_HACK_REGEXP = /^moz([A-Z])/;
function camelCase(name) {
    return name.replace(SPECIAL_CHARS_REGEXP, function (_, separator, letter, offset) {
        return offset ? letter.toUpperCase() : letter;
    }).replace(MOZ_HACK_REGEXP, 'Moz$1');
}


/**
 * 获取样式属性值
 * @param element
 * @param styleName
 * @return {*}
 */
function getStyle(element, styleName) {
    if (!element || !styleName) return null;
    styleName = camelCase(styleName);
    if (styleName === 'float') {
        styleName = 'cssFloat';
    }
    try {
        const computed = document.defaultView.getComputedStyle(element, '');
        return element.style[styleName] || computed ? computed[styleName] : null;
    } catch (e) {
        return element.style[styleName];
    }
}

/**
 * 计算rem
 * @param doc
 * @param win
 */
function calculateRem(doc, win) {
    var docEl = doc.documentElement,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        reCalc = function () {
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if (clientWidth >= 1280) {
                docEl.style.fontSize = '100px';
            } else {
                docEl.style.fontSize = 100 * (clientWidth / 1280) + 'px';
            }
        };
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, reCalc, false);
    doc.addEventListener('DOMContentLoaded', reCalc, false);
}

/**
 * 元素显隐切换
 * @param ele
 */
function toggle(ele) {
    let display = getStyle(ele, 'display');
    display = display === 'none' ? 'block' : 'none';
    ele.style.display = display;
}

/**
 * dom元素添加类
 * @param obj
 * @param cls
 */
function addClass(obj, cls) {
    var obj_class = obj.className,//获取 class 内容.
        blank = (obj_class != '') ? ' ' : '';//判断获取到的 class 是否为空, 如果不为空在前面加个'空格'.
    var added = obj_class + blank + cls;//组合原来的 class 和需要添加的 class.
    obj.className = added;//替换原来的 class.
}

/**
 * dom元素移除类
 * @param obj
 * @param cls
 */
function removeClass(obj, cls) {
    var obj_class = ' ' + obj.className + ' ';//获取 class 内容, 并在首尾各加一个空格. ex) 'abc    bcd' -> ' abc    bcd '
    obj_class = obj_class.replace(/(\s+)/gi, ' '),//将多余的空字符替换成一个空格. ex) ' abc    bcd ' -> ' abc bcd '
        removed = obj_class.replace(' ' + cls + ' ', ' ');//在原来的 class 替换掉首尾加了空格的 class. ex) ' abc bcd ' -> 'bcd '
    var removed = removed.replace(/(^\s+)|(\s+$)/g, '');//去掉首尾空格. ex) 'bcd ' -> 'bcd'
    obj.className = removed;//替换原来的 class.
}


/**
 * 悬浮某一元素，显示另一元素
 * @param showElements {NodeList} 显示元素集合或者单个元素
 * @param hideElement  { Object }       隐藏元素
 * @param showEnterFn  { function } 显示元素悬浮时的回调函数
 * @param showLeaveFn  { function } 显示元素离开时的回调函数
 * @param ctx          { Object } 上下文
 * @param capture      { function } 函数返回true, 不执行回调函数
 */
function enterLeave(showElements, hideElement, showEnterFn, showLeaveFn, ctx, capture) {
    var leave = false, timer = null;

    if (Object.prototype.toString.call(showElements).indexOf('array') == -1
        && Object.prototype.toString.call(showElements).indexOf('NodeList') == -1) {
        showElements = [showElements];
    }

    for (var s = 0; s < showElements.length; s++) {
        var showElement = showElements[s];
        (function (showElement, idx) {
            showElement.addEventListener('mouseenter', function (evt) {
                if (typeof capture === 'function' && capture()) {
                    return;
                }
                leave = false;
                hideElement.style.display = 'block';
                if (showEnterFn) {
                    if (!ctx) {
                        ctx = showElement;
                    }
                    showEnterFn.call(ctx, evt, showElement, idx);
                }
            });

            showElement.addEventListener('mouseleave', function (evt) {
                if (typeof capture === 'function' && capture()) {
                    return;
                }
                mouseLeave();
                if (showLeaveFn) {
                    if (!ctx) {
                        ctx = showElement;
                    }
                    showLeaveFn.call(ctx, evt, showElement);
                }
            });
        })(showElement, s);
    }
	
	if(hideElement){
		hideElement.addEventListener('mouseenter', function () {
	        leave = false;
	    });
	
	    hideElement.addEventListener('mouseleave', mouseLeave);
	}
    

    function mouseLeave() {
        leave = true;

        if (timer) {
            return;
        }

        timer = setTimeout(function () {
            if (leave) {
                hideElement.style.display = 'none';
            }
            timer = null;
        }, 200);
    }
}



/***********************************************************************************************************************
 * *********************************************************************************************************************
 * nav.js
 */

class app{

    constructor(){

        this.init()
    }

    init(){

        this.eventRegister();
    }
    
    eventRegister() {
     
        this.mobileMenuClick();
      
        this.navHasItemsEvent();
       
        this.windowResize();
    }

    mobileMenuClick() {
       
        let navbarToggle = document.querySelector('.navbar-toggle');
		
		
		 navbarToggle.addEventListener('click', () => {

            let navbar = document.getElementById('navbar');
            
            toggle(navbar);
            
        });
    }
     
    navHasItemsEvent() {
        // 上一次激活的菜单
        let preActiveElement, hoverMenuLeave, hoverMenuTimer;
        // 菜单展开折叠切换
        let menuItems = document.querySelectorAll('.has-items');

        for (let m of menuItems) {
            (function (m, ctx) {
                m.addEventListener('click', () => {
                    if (ctx.isMobile()) {
                        let leaf = m.querySelector('.leaf');
                        toggle(leaf);
                    }
                });
            })(m, this);
        }

        let hoverMenu = document.querySelector('.hover-menu');

        //悬浮显示元素
        enterLeave(menuItems, hoverMenu, function (evt, m) {
			
            let id = m.getAttribute('data-target');
			
            let activeElement = document.getElementById(id);
            addClass(activeElement, 'active');
            if (preActiveElement) {
                removeClass(preActiveElement, 'active');
            }
            preActiveElement = activeElement;
        }, function () {

        }, this, this.isMobile);
    }
    
    windowResize() {

        window.addEventListener('resize', () => {
            if (!this.isMobile()) {
                let mobileDoms = document.querySelectorAll('.mobile');
                for (let mobileDom of mobileDoms) {
                    mobileDom.style.display = 'none';
                }
            } else {
                let downIcons = document.querySelectorAll('.mobile.glyphicon-menu-down');
                for (let downIcon of downIcons) {
                    downIcon.style.display = 'block';
                }
            }
        });
    }

    isMobile() {
        let navbarToggle = document.querySelector('.navbar-toggle');
        // console.log(navbarToggle)
        let display = getStyle(navbarToggle, 'display');
        return display != 'none';
    }

}

setTimeout(function(){
	new app()
},500)





