module.exports = function (obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

var util=require("components/utils/dateUtils").default;var img=require("lib/images/no_preview.png");;
__p += '\r\n\r\n<div class="" id="gallery">\r\n    ';
 itemArray.forEach(function(item){;
__p += '\r\n    <div class="item-list-style">\r\n\r\n        <div class="item-content row">\r\n            <div class="col-xs-6 col-md-3">\r\n\r\n                <a class="thumbnail" href="./item_onemap.html?id=' +
((__t = (item.serviceId)) == null ? '' : __t) +
'"  target="_blank">\r\n                    <img src="' +
((__t = (config.ipurl)) == null ? '' : __t) +
'pskj/api/servicesinfo/query/getServiceFile?fileName=' +
((__t = (item.extentProps)) == null ? '' : __t) +
'" data-errorimg="' +
((__t = (img)) == null ? '' : __t) +
'" onerror="this.onerror=null;this.src=this.dataset.errorimg;" alt="' +
((__t = (item.serviceFullName)) == null ? '' : __t) +
'"></a>\r\n            </div>\r\n            <div class="col-md-9">\r\n                <div class="item-header">\r\n                    <span class="item-title">\r\n                        ' +
((__t = (item.serviceFullName)) == null ? '' : __t) +
'</span>\r\n                        \r\n                        ';
if(!item.showToHide){;
__p += '\r\n                        	';
if(item.hasLogged){;
__p += '\r\n		                    	<a class="down_load_btn" data-id="' +
((__t = (item.serviceId)) == null ? '' : __t) +
'" style="margin-left:2px;cursor: pointer;color:#337ab7;font-size: 1.2em;" data-remodal-target="modal-apply">\r\n						          <span>(资源申请)</span>\r\n						        </a>\r\n		                    ';
}else{;
__p += '\r\n		                    	<a class="down_load_btn" href="login.html" target="_self" style="margin-left:2px;font-size: 1.2em;">(资源申请)</a>\r\n		                    ';
};
__p += '\r\n                        ';
};
__p += '\r\n                    <a href="./item_onemap.html?id=' +
((__t = (item.serviceId)) == null ? '' : __t) +
'" class="item-detal-entry pull-right"  target="_blank">详细信息>></a>\r\n                </div>\r\n                <dl class="item-properties dl-horizontal">\r\n                    <div class="item-property-container">\r\n                        <dt>提供单位：</dt>\r\n                        <dd>\r\n                            ' +
((__t = (item.fullPermission||"暂无提供单位信息")) == null ? '' : __t) +
'\r\n                        </dd>\r\n                    </div>\r\n                    <div class="item-property-container">\r\n                        <dt>发布时间：</dt>\r\n                        <dd>\r\n                            ' +
((__t = (item.regDate||"暂无发布时间信息")) == null ? '' : __t) +
'\r\n                        </dd>\r\n                    </div>\r\n                    <div class="item-property-container">\r\n                        <dt>摘要信息：</dt>\r\n                        <dd>\r\n                            ' +
((__t = (item.description||"暂无摘要信息")) == null ? '' : __t) +
'\r\n                        </dd>\r\n                    </div>\r\n                    <div class="item-property-container">\r\n                        <dt>关键字：</dt>\r\n                        <dd>\r\n							' +
((__t = (item.memo||"暂无关键字")) == null ? '' : __t) +
'\r\n                        </dd>\r\n                    </div>\r\n                    <div class="item-property-container">\r\n                        <dt>浏览量：</dt>\r\n                        <dd>\r\n                        	';
if(item.count){;
__p += '\r\n                        		' +
((__t = (item.count+"次")) == null ? '' : __t) +
'\r\n                        	';
}else{;
__p += '\r\n                        		0次\r\n                        	';
};
__p += '\r\n                        </dd>\r\n                    </div>\r\n                </dl>\r\n            </div>\r\n\r\n        </div>\r\n    </div>\r\n    ';
});
__p += '\r\n</div>';

}
return __p
}