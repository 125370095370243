import 'bootstrap/dist/css/bootstrap.min.css';
import 'components/style/base.css';
import 'components/style/page.css';
import 'components/style/custom-sweetalert.css';
import {renderHeader} from "components/header/header";
import {renderFooter} from 'components/footer/footer';


/**
 * 所有页面的基础类，用于加载基本的样式和基础组件
 *
 * @class BaseApp
 */
class BaseApp{
    constructor(){
        this.initPage().addComponents();
    }   

    initPage(){
        //设置标题
        document.title=config.title;

        //添加样式文件
        const styleLink= document.createElement('link');
        styleLink.setAttribute('href',config.theme);
        styleLink.setAttribute('rel','stylesheet');
        document.head.appendChild(styleLink);

        return this;
    }

    addComponents(){
        if(document.getElementById('header'))renderHeader();
        if(document.getElementById('footer'))renderFooter();
        return this;
    }
}

export default BaseApp;