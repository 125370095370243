module.exports = function (obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div  class="row tabgallery" id="gallery">\r\n';
 itemArray.forEach(function(item){;
__p += '\r\n    <div class="col-sm-6 col-md-3 col-xs-12 col-lg-3">\r\n        <div class="thumbnail" name="' +
((__t = (item.servicefullname)) == null ? '' : __t) +
'">\r\n            <div class="collection"></div>\r\n            <a href="item_onemap.html?id=' +
((__t = (item.serviceid)) == null ? '' : __t) +
'" target="_blank">\r\n                <img src="' +
((__t = (item.snapurl||require('../img/no_preview.png'))) == null ? '' : __t) +
'" class="img-responsive" alt="maponline-test-save">\r\n            </a>\r\n            <div class="caption">\r\n                <p class="author-info">\r\n                    <span class="author-name text-overflow"></span>\r\n                    <span class="view-count pull-right">\r\n                        <span class="glyphicon glyphicon-eye-open" aria-hidden="true"></span>&nbsp;\r\n                        ' +
((__t = (item.count)) == null ? '' : __t) +
'\r\n                    </span>\r\n                </p>\r\n                <p class="thumbnail-label text-overflow">\r\n                    ' +
((__t = (item.servicefullname)) == null ? '' : __t) +
'\r\n                </p>\r\n                <p style="overflow: hidden; white-space: nowrap;text-overflow: ellipsis;">\r\n                    ' +
((__t = (item.servicetypename)) == null ? '' : __t) +
'\r\n                </p>\r\n                <p class="thumbnail-description">\r\n                    ' +
((__t = (item.description)) == null ? '' : __t) +
'\r\n                </p>\r\n                <p class="thumbnail-time">\r\n                    <span class="author-avatar">\r\n                        <img name="super" src="' +
((__t = (require('../img/no-user-thumb.jpg'))) == null ? '' : __t) +
'">\r\n                    </span>\r\n                    <span code="super" class="author-name  text-overflow">&nbsp;\r\n                        ' +
((__t = (item.registername||"超级管理员")) == null ? '' : __t) +
'\r\n                    </span>\r\n                    <span class="comment-count pull-right">\r\n                        ' +
((__t = (new Date(item.regdate).toLocaleString().split(' ')[0])) == null ? '' : __t) +
'\r\n                    </span>\r\n                </p>\r\n                <div class="box-footer">\r\n                    <div class="container-fluid">\r\n                        <div class="col-md-12">\r\n                            <a href="item_onemap.html?id=' +
((__t = (item.serviceid)) == null ? '' : __t) +
'" title="查看详情" target="_blank">详情</a>\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n    ';
});
__p += '\r\n\r\n\r\n    </div>';

}
return __p
}