import './list.css';
import 'lib/pagination/pagination.css';

import * as login from 'components/login/login';
import AbstractGallery from './basegallery.resource';
import template_list from './template/list-resource-onemapservices.ejs';
import template_card from './template/gallery-onemap-index.ejs';
import emptyTemplate from './template/empty.ejs';
import 'lib/pagination/jquery.pagination';
import * as status from 'components/login/status';
import pretreatment from './pretreatment';
import axios from 'axios';

/**
 * 用于在首页和资源中心页面展示OneMap的服务
 *
 * @class Gallery
 * @extends {AbstractGallery}
 */
class Gallery extends AbstractGallery{
    constructor(options){
        super(options);
    }

    prepareQueryString(){
        return this.queryParam;
    }

    request(option){
        option=option||{};
        if(this.keyWord){
            option.findKey=this.keyWord;
        }
        option.pageNo=option.offset||1;
        option.rowsCount=this.count_per_page;
        if(this.queryParam.cataloguuids!=37 && this.queryParam.cataloguuids!=116){
        	option.classId=this.queryParam.cataloguuids
        }
        if(!this.queryParam.cataloguuids){
            delete option.cataloguuids;
        }
        option.orderfield= option.sort||this.sortField;
        option.ordertype=option.order||this.sortOrder;
        if(status.default.getUserId()){
        	//if(!login.isManager()){
	        	option.userid = status.default.getUserId();
	       // }
        }
        
        option.subjectType = 1;
        
        return axios.get(`${config.urls.category_service}`,{
            params:option
        }).then((result)=>{
            result=result.data;
            if(result.code!=="SUCCESS"){
                return Promise.reject('请求错误');
            }
            else{
                const data=result.data||[];
                this.onRequest(data);
                return data;
            }
        })
        .catch((err)=>{
            console.error(err);
            return {
                total:0,
                rows:[]
            }
        });
    };

    render(data){
        this.destroy();
        this.renderGallery(data.servicesInfoList||[]);
        this.renderPagination(data.sumCount||0);
        if(data.sumCount){
        	this.initPagination(data.sumCount)
        }
        $('#onemap-resource .down_load_btn').unbind().click(function(){
        	var dataId = $(this).attr('data-id');
        	document.forms[0].reset();
        	if(dataId){
        		$('#downId').val(dataId);
        	}
        })
    }

    renderGallery(array){
        if(this.listStyle==='list'){
            this.template=template_list;
        }
        else if(this.listStyle==='card'){
            this.template=template_card;
        }
        if(!array){
            array=[];
        }
        array.map((item)=>{
            item=pretreatment.thumbnail(item,status.default.getPortalToken());
            item=pretreatment.openUrl(item);
            item=pretreatment.getExternalFields(item);
            item['isAdministrator'] = status.default.getUserType() > 0; //管理员不能申请服务
      		item['hasLogged'] = status.default.getOneMapToken() //未登录不能申请
      		
            return item;
        })

        const div=document.createElement('div');
        let html;
        if(array.length>0){
            html= this.template({itemArray:array});
        }else{
            html=emptyTemplate();
        }

        div.innerHTML=html;
        const firstChild=this.container.firstChild;
        this.container.insertBefore(div,firstChild);
        return this;
    };

    renderPagination(){
        const div=document.createElement('div');
        div.innerHTML='<div id="pagination-onemapservices" class="pagination-jq pull-right"></div>';
        this.container.appendChild(div);
        return this;
    }

    initPagination(total){
        const self=this;
        //初始化分页组件
        $("#pagination-onemapservices").pagination(total, {
            'items_per_page': this.count_per_page,
            'current_page': 0, //默认0，第一页
            'num_display_entries': 5,
            'num_edge_entries': 1,
            'prev_text': "上一页",
            'next_text': "下一页",
            'link_to': "javascript:void(0);",
            'callback': function(n) {
                self.request({
                    offset:n+1,
                }).then((data)=>{
                    self.destroyGallery();
                    self.renderGallery(data.servicesInfoList);
                });
            }
        });
    };

    sortHandler(sortField,sortOrder){
        this.sortField=sortField;
        if(!sortOrder){
            this.sortOrder='desc';
        }
        else{
            this.sortOrder=sortOrder;
        }
        this.refresh();
    }

    queryParamChangeHandler(queryParam,commonParam){
        this.queryParam=queryParam;
        this.sortOrder=commonParam.sortOrder;
        this.sortField=commonParam.sortField;
        this.refresh();
    }

    filterExtentprops(items){
        //只显示部分关键字
        items.forEach((item)=>{
            item.extentpropsList=item.extentpropsList.filter((extentprop)=>{
                return extentprop.propname==='服务机构'||extentprop.propname==='专题分类'||extentprop.propname==='服务类型'
            })
        });
        return items;
    }
}

export default Gallery;
