function thumbnail(item, token) {
    if (item.thumbnailUrl) {
        return item;
    }

    if (item.thumbnail) {
        let tokenParm = token ? `?token=${token}` : '';
        if (item.access === 'public') {
            tokenParm = '';
        }
        item.thumbnailUrl = `/arcgis/sharing/rest/content/items/${item.id}/info/${item.thumbnail}` + tokenParm;
    } else {
        item.thumbnailUrl = require('../../lib/images/desktopapp.png');
    }
    return item;
}

function openUrl(item) {
    if (!item.type || item.open) {
        return item;
    }
    const openableMapType = ['Web Map'];
    const openableLayerType = ["Feature Collection", 'Map Service', 'Feature Service', 'WMS', 'WMTS'];
    const openableApplicationType = ['Web Mapping Application'];
    const openableDocumentType = ['Document Link'];

    if (openableMapType.indexOf(item.type) > -1) {
        item.open = `./webmap.html?webmap=${item.id}`;
    } else if (openableLayerType.indexOf(item.type) > -1) {
        item.open = `./webmap.html?layers=${item.id}`;
    } else if (openableApplicationType.indexOf(item.type) > -1) {
        if (item.typeKeywords.indexOf('Configurable') === -1) {
            item.open = `${item.url}`;
        }
    } else if (openableDocumentType.indexOf(item.type) > -1) {
        item.open = `${item.url}`;
    }

    return item;
}

function filterUndisplayItem(items) {
    const arr = ['Code Attachment'];
    return items.filter(element => {
        if (element.type && arr.indexOf(element.type) === -1) {
            return element;
        }
    });
}

/**
 * 获取扩展字段，添加到服务的属性中 ,适用于OneMap服务
 *
 * @param {*} item
 * @returns
 */
function getExternalFields(item){
    const extentpropsList=item.extentpropsList;
    if(extentpropsList instanceof Array&&extentpropsList.length>0){
        extentpropsList.forEach(function(field){
            const propname=field.id.propname;
            if(propname==="KeyWord"){
                item[propname]=field.propvalue.split(";");
            }
            else{
                item[propname]=field.propvalue;
            }
        });
    }
    return item;
}


function replaceItemOwner(items,users){
    return items.map((item)=>{
        const owner=item.owner;
        users.forEach((user)=>{
            // if(user.)
        });
        return item;
    })
}

export default {
    thumbnail,
    openUrl,
    filterUndisplayItem,
    getExternalFields
}