module.exports = function (obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<!-- 参数：pagelist,name -->\r\n<nav aria-label=\'Page navigation\'>\r\n    <ul class=\'pagination pagination-custom\'>\r\n        <li>\r\n            <a href="#" aria-label="Previous" data-pagenum="previous">\r\n                <span aria-hidden=\'true\'>&laquo;</span>\r\n            </a>\r\n        </li>\r\n        ';
pagelist.forEach(function(pagenum){;
__p += '\r\n            <li><a id="' +
((__t = (name+'_pageBtn_'+pagenum)) == null ? '' : __t) +
'" href=\'#\' data-pagenum=\'' +
((__t = (pagenum)) == null ? '' : __t) +
'\'>' +
((__t = ( pagenum )) == null ? '' : __t) +
'</a></li>\r\n        ';
});
__p += '\r\n        <li>\r\n            <a href="#" aria-label="Next" data-pagenum="next">\r\n                <span aria-hidden=\'true\'>&raquo;</span>\r\n            </a>\r\n        </li>       \r\n    </ul>\r\n</nav>';

}
return __p
}