import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import './comments-panel.css';
import template from './comments-panel.ejs';
import dateutils from 'components/utils/dateUtils';

class module{
    constructor(commentlist,containerId)
    {
        this.containerId=containerId;
        this.commentlist=commentlist;
        this.init();
    }

    init(){
        var container=document.getElementById(this.containerId);
        if(!container||!this.commentlist)
            return;
        //加载之前先处理下时间
        var loopCount=this.commentlist.length;
        var date=new Date();
        for(var i=0;i<loopCount;i++){
            date.setTime(this.commentlist[i].time);
            this.commentlist[i].time=dateutils.toDatetimeString(date);
        }
        var html=template({commentlist:this.commentlist});
        container.innerHTML=html;
    }
}

export default module;