import template from './template/gallery.ejs';
import emptyTemplate from './template/empty.ejs';
import paginationTemplate from './template/pagination.ejs';
import arcgis from 'components/portal/index';
import 'lib/pagination/jquery.pagination';
import status from 'components/login/status';
import pretreatment from './pretreatment';
import queryStrings from './portalSourceQueryString';

import 'lib/pagination/pagination.css';
import './gallery.css';

/**
 * 用于展示item的基础类
 *
 * @class Gallery
 */
class Gallery { 
    constructor(param,containerId,num,sortField,sortOrder){
        containerId=containerId||'content-wrapper';
        this.container=document.getElementById(containerId);

        this.queryParam=param;
        this.count_per_page=num||9;

        this._sortField=sortField||"createtime";//保存排序的字段 默认为numviews
        this._sortOrder=sortOrder||"desc";//保存排序的顺序  默认为降序排列
        this._keyWord='';//保存搜索的关键字

        this.template=template;

        this._onRequest=function(){};
        this._onRendered=function(){}

        this.request().then((data)=>{
            this.render(data);
            if(data.total>this.count_per_page&&this.count_per_page>=9){//第二个条件是为了首页的正常显示做的限制
                this.initPagination(data.total);
            }
        });
    }

    get sortField(){return this._sortField;}
    set sortField(sortField){this._sortField=sortField;}
    get sortOrder(){return this._sortOrder;}
    set sortOrder(sortOrder){this._sortOrder=sortOrder;}

    get onRequest(){return this._onRequest}
    set onRequest(value){this._onRequest=value;}
    get onRendered(){return this._onRendered}
    set onRendered(value){this._onRendered=value;}

    request(option){
        option=option||{};
        option.queryString=option.queryString||this.prepareQueryString();
        if(this.keyWord){
            option.queryString+=`(${this.keyWord})`;
        }
        option.num=this.count_per_page||option.num||9;
        option.page=option.page||0;
        option.sort= option.sort||this.sortField;
        option.order=option.order||this.sortOrder;
        option.token=option.token||'';
        return arcgis.search(option);
    };

    initPagination(total){
        const self=this;
        //初始化分页组件
        $("#pagination").pagination(total, {
            'items_per_page': this.count_per_page,
            'current_page': 0, //默认0，第一页
            'num_display_entries': 5,
            'num_edge_entries': 1,
            'prev_text': "上一页",
            'next_text': "下一页",
            'link_to': "#",
            'callback': function(n) {
                self.request({
                    page:n
                }).then((data)=>{
                    self.destroyGallery();
                    self.renderGallery(data.results);
                });
            }
        });
    };

    refresh(){
        this.request().then((data)=>{
            this.render(data);
            if(data.total>this.count_per_page&&this.count_per_page>=9){//第二个条件是为了首页的正常显示做的限制
                this.initPagination(data.total);
            }
        });
    }

    render(data){
        this.destroy();
        this.renderGallery(data.results);
        this.renderPagination(data.total);
    }

    renderGallery(array){
        array.map((item)=>{
            item=pretreatment.thumbnail(item,status.getPortalToken());
            item=pretreatment.openUrl(item);
            return item;
        })

        const div=document.createElement('div');
        let html;
        if(array.length>0){
            html= this.template({itemArray:array});
        }else{
            html=emptyTemplate();
        }

        div.innerHTML=html;
        const firstChild=this.container.firstChild;
        this.container.insertBefore(div,firstChild);
        return this;
    };

    renderPagination(){
        const html=paginationTemplate();
        const div=document.createElement('div');
        div.innerHTML=html;
        this.container.appendChild(div);
        return this;
    };

    sortHandler(sortField,sortOrder){
        this.sortField=sortField;
        if(!sortOrder){
            this.sortOrder='desc';
        }
        else{
            this.sortOrder=sortOrder;
        }
        this.refresh();
    }

    searchHandler(keyWord){
        this.keyWord=keyWord;
        this.refresh();
    }

    catalogChangehandler(tag){
        this.tag=tag;
        this.request().then((data)=>{
            this.render(data);
        });
    }

    destroyGallery(){
        this.container.removeChild(this.container.firstChild);
        return this;
    }

    destroy(){
        while(this.container.hasChildNodes()){
            this.container.removeChild(this.container.firstChild);
        } ;
        return this;
    };
}

export default Gallery;