import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './user-registerInfo.css';
import axios from 'axios';
import qs from 'qs';
import * as login from 'components/login/login';
import template from './user-registerInfo.ejs';


import {
    error
} from 'util';


//按钮区域 清屏 姓名
const buttonZoneClsName = 'button-zone';
var startUser = false;

//要求焦点名称
const requireInfoClsName = 'require-info';

//控制单的id
Widget.prototype.containerId = "";

//参数
Widget.prototype.params = {
    //四种编辑模式:
    //regisiter：注册新用户,edit_baseinfo：更新基本信息，edit_password：修改密码,edit_none：不编辑
    editMode: "register",
    submitButtonName: "注册",
    userInfo: {
        userName: "",
        fullName: "",
        userType: "0",
        userFrom:"1",
        password: "",
        email: "",
        rtelePhone: "",
        postcode: "",
        address: "",
        applyInfo: "",
        orgName: "普通员组",
        status:"1",
        org: "2",
    },
    //提交按钮回调函数，如果存在，会把页面信息保存到userInfo中传回去
    onSubmit: null,
    //组织机构树
    orgtree: {}
}

//控制id s  控制复数id
Widget.prototype.controlIds = ['userName', "fullName", "password", 'oldPassword', "confirmpsw", 'email', 'rtelePhone', 'postcode', 'address', 'applyInfo'];

//显示器
Widget.prototype.displayParams = {
    modules: new Object(),
    showButtons: true,
    submitButtonName: "注册",
    showAsterisk: true
};

//初始化
Widget.prototype.init = function (params) {
    var that = Widget.prototype;
    this.params = that.params;
    if (!params) {
        return;
    } else {
        this.params = Object.assign(this.params, params);
    }
}

//根据编辑模式来调整显示参数
Widget.prototype.initDisplayParams = function () {
    var that = Widget.prototype;
    this.displayParams=that.displayParams;
    //初始化显示参数
    that.controlIds.forEach((controlId) => {
        this.displayParams.modules[controlId]={
            visible: true,
            editable: true
        }
    });
    var invisibleContorls = [];
    var readonlyContorls = [];
    var showButtons = true;
    var showAsterisk = true;
    this.displayParams.submitButtonName = this.params.submitButtonName;
	
    switch (this.params.editMode) {
        case "edit_baseinfo":
            {
                //更新基本信息,密码信息不显示,用户名称不允许编辑
                invisibleContorls = ['oldPassword', 'password', 'confirmpsw', 'applyInfo'];
                readonlyContorls = ['email'];
                this.displayParams.showButtons = true;
                this.displayParams.showAsterisk = true;
                break;
            }
        case "edit_password":
            {
                //更改密码,其他信息不显示，只显示密码信息
                const outArray = ['oldPassword', 'password', 'confirmpsw'];
                invisibleContorls = this.controlIds.filter((controlId) => {
                    return outArray.indexOf(controlId) === -1;
                });
                this.displayParams.showButtons = true;
                this.displayParams.showAsterisk = false;
                break;
            }
        case "edit_none":
            {
                //非编辑状态，所有信息都不允许编辑,不显示按钮
                const outArray = ['oldPassword', 'password', 'confirmpsw', 'applyInfo'];
                invisibleContorls = this.controlIds.filter((controlId) => {
                    return outArray.indexOf(controlId) === -1;
                });
                readonlyContorls = this.controlIds.slice();
                this.displayParams.showButtons = false;
                this.displayParams.showAsterisk = false;
                break;
            }
        default:
            {
                //默认状态为注册状态，即除了旧密码不显示之外，其他全部显示并且可以编辑
                invisibleContorls = ['oldPassword'];
                break;
            }
    }
    //调整显示参数
    invisibleContorls.forEach((controlId) => {
        this.displayParams.modules[controlId].visible = false;
    })

    readonlyContorls.forEach((controlId) => {
        this.displayParams.modules[controlId].editable = false;
    })
}

//初始化界面
Widget.prototype.initView = function () {
    var container = document.getElementById(this.containerId);
    if (!container) {
        return;
    }
    const html = template();
    container.innerHTML = html;
    //根据显示设置来调整界面布局
    //控制按钮组的显示
    if (!this.displayParams.showButtons) {
        var buttonDiv = document.getElementById('input-group-buttons');
        if (buttonDiv) {
            buttonDiv.classList.add('invisible');
        }
    } else {
        document.getElementById('btn-submit').innerHTML = this.displayParams.submitButtonName;
        var that = this;
        document.getElementById('btn-submit').onclick = function (event) {
            event.preventDefault();
            that.submit();
        };
		
        document.getElementById('btn-reset').onclick = function (event) {
            event.preventDefault();
            that.reset();
        }
		
    }
    //控制星号的显示
    if (!this.displayParams.showAsterisk) {
        var asteriskDivs = container.getElementsByClassName('require-info');
        // asteriskDivs.forEach((asteriskDiv) => {
        //     asteriskDiv.innerHTML = "";
        // });
        for(var i=0;i<asteriskDivs.length;i++){
            asteriskDivs[i].innerHTML="";
        }
    }
    //控制模块的显示
    for (var key in this.displayParams.modules) {
        if (this.displayParams.modules.hasOwnProperty(key)) {
            const value = this.displayParams.modules[key];
            if (!value.visible) {
                document.getElementById('input-group-' + key).classList.add('invisible');
                document.getElementById('input-' + key).classList.add('invisible');
            } else {
                if (!value.editable) {
                    document.getElementById('input-' + key).setAttribute('readonly', 'readonly');
                }
            }
        }
    } 

    //则将参数中的用户信息渲染到界面上
    var temp_userInfo = this.params.userInfo;
    for (var key in temp_userInfo) {
        if (temp_userInfo.hasOwnProperty(key)) {
            var value = temp_userInfo[key];
            var input = document.getElementById('input-' + key);
            if (input) {
                input.value = value == 'N/A' ? '' : value;
            }
        }
    }

    //注册事件
    var that = this;
    var temp_modules = this.displayParams.modules;
    for (var key in temp_modules) {
        if (temp_modules.hasOwnProperty(key)) {
            const value = temp_modules[key];

            if (!value.visible) {
                document.getElementById('input-group-' + key).classList.add('invisible');
                document.getElementById('input-' + key).classList.add('invisible');
            } else {
                if (!value.editable) {
                    document.getElementById('input-' + key).setAttribute('readonly', 'readonly');
                }
            }
            if (value.visible && value.editable) {
                //注册焦点捕捉事件
                //当控件获取焦点时，显示提示
                document.getElementById('input-' + key).onfocus = function (e) {
                    //如果有错误提示，应该将错误提示取消
                    e.target.classList.remove('error');
                    var errorHint = e.target.parentNode.getElementsByClassName('userinfo-hint error');
                    if (errorHint.length > 0) {
                        e.target.parentNode.removeChild(errorHint[0]);
                    }
                    if (!e.target.value || e.target.value == null || e.target.value == '') {
                        e.target.parentNode.getElementsByClassName('userinfo-hint')[0].classList.remove('invisible');
                    }
                }
                //当控件失去焦点时，检查输入值，如果出错，将错误显示出来
                document.getElementById('input-' + key).onblur = function (e) {
                    e.target.parentNode.getElementsByClassName('userinfo-hint')[0].classList.add('invisible');
                    var val = e.target.value;
                    var name = e.target.id.split('-')[1];
                    if (name) {
                    	var checkResult = that.checkInput(name, val);
	                        if (!checkResult.success) {
	                            e.target.classList.add('error');
	                            var errorHint = document.createElement('p');
	                            errorHint.className = 'userinfo-hint error';
	                            e.target.parentNode.appendChild(errorHint);
	                            errorHint.innerHTML = checkResult.message;
	                        }
                    }
                }
            }
        }
    }
}


//提交按钮点击事件
Widget.prototype.submit = function () {
    //获取界面上所有的用户输入
    var container = document.getElementById(this.containerId);
    if (!container)
        return;
    var inputs = container.getElementsByClassName("userinfo-input");
    var suc = true;
	
	//类数组转数组
	var arrinputs = Array.prototype.slice.call(inputs)
	// //注册事件
	var that = this;
	 //检查输入
	arrinputs.forEach(function (inputEle) {
	    if (!suc) return false;
	    //隐藏的输入组件不用考虑
	    if (inputEle.classList.contains('invisible')) {
	        return true;
	    }
	    var controlId = inputEle.id.split('-')[1];
	    var value = inputEle.value;
		
		
	    var checkResult = that.checkInput(controlId, value);
	    if (!checkResult.success) {
	        that.setContorlError(controlId);
	        that.updateMessage(checkResult.message, true);
	        suc = false;
	        return false;
	    }
		if(controlId=="email"){
			that.params.userInfo["userName"] = value;
		}
	    that.params.userInfo[controlId] = value;
	});


    if (!suc) {
        return;
    }
    
    //检查密码是否对应
    if (this.editMode === "register" || this.editMode === 'edit_password')
        if (!this.checkPassword()) {
            this.setContorlError('password');
            this.setContorlError('confirmpsw');
            this.updateMessage('两次输入的密码请保持一致', 'true');
            suc = false;
        }
    //如果有回调，将用户信息作为参数，调用回调
    if (this.params.onSubmit && suc) {
        this.params.onSubmit(this.params.userInfo);
    }
}


//密码框
Widget.prototype.checkPassword = function () {
    if (this.params.userInfo.password && this.params.userInfo.password != '') {
        //确认密码必须与密码相同
        if (this.params.userInfo.confirmpsw &&
            this.params.userInfo.confirmpsw == this.params.userInfo.password) {

            return true;
        } else {
            return false;
        }
    }
}

//重置
Widget.prototype.reset = function () {
    //重置界面上所有的input元素
    var container = document.getElementById(this.containerId);
    if (!container)
        return;
    this.updateMessage('');
    var inputs = container.getElementsByClassName("userinfo-input");
	
	//类数组转数组
	var arrinputs = Array.prototype.slice.call( inputs )
	
    arrinputs.forEach(function (inputEle) {
        if(inputEle.getAttribute("disabled") == null){
            inputEle.value = null;
            inputEle.classList.remove('error');
        }
   });
   
}



//表单checkInput 设置方法  表单验证
Widget.prototype.checkInput = function (controlId, inputValue){
    var requireIds = ['email', 'fullName', 'password', 'confirmpsw', 'oldPassword'];
    //目前只校验必填项，后续再补充
    if (requireIds.indexOf(controlId) != -1) {
        if (!inputValue || inputValue === '') {
            return {
                success: false,
                message: "必填项不可为空"
            };
        }
    }
    //校验用户名，用户名只能是英文、数字或者下划线
//  if (controlId == 'userName') {
//      var reg = /\W/;
//      if (reg.test(inputValue)) {
//          return {
//              success: false,
//              message: '登录名只支持字母、数字和下划线'
//          };
//      }
//  }
	
    //校验邮箱
    if (controlId == 'email') {
        var reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
        if (!reg.test(inputValue)) {
            return {
                success: false,
                message: '请输入合法的邮箱的地址'
            }
        }
    }
    //校验密码,密码必须同时包含数字和字母两种
    //校验密码,密码必须同时包含数字和字母两种
    if (controlId == 'password') {
        if (inputValue.length > 16 || inputValue.length < 8) {
            return {
                success: false,
                message: '密码应为8-16位'
            }
        }
        
        var regText = /([a-zA-Z]+[0-9]+[!@#$%^&,.*]+)|([a-zA-Z]+[!@#$%^&,.*]+[0-9]+)|([0-9]+[!@#$%^&,.*]+[a-zA-Z]+)|([0-9]+[a-zA-Z]+[!@#$%^&,.*]+)|([!@#$%^&,.*]+[a-zA-Z]+[0-9]+)|([!@#$%^&,.*]+[0-9]+[a-zA-Z]+)/;
        if (!regText.test(inputValue)) {
            return {
                success: false,
                message: '必须包含字母、数字和特殊字符'
            }
        }
        
//      var regNumber = /\d+/;
//      var regText = /([A-Z]|[a-z])+/;
//      if (!regNumber.test(inputValue) || !regText.test(inputValue)) {
//          return {
//              success: false,
//              message: '密码必须同时包含字母和数字'
//          }
//      }
//      var regInvalid = /(\W|_)/;
//      if (regInvalid.test(inputValue)) {
//          return {
//              success: false,
//              message: '密码只能包含数字和字母'
//          }
//      }
    }
    // 确认密码必须与密码相同
    if (controlId == 'confirmpsw') {
        if (inputValue != document.getElementById('input-password').value) {
            return {
                success: false,
                message: '两次请输入相同的密码'
            }
        }
    }
    //校验电话号码
    if (controlId == 'rtelePhone' && inputValue) {
        var regTel = /^0[1-9]{2,3}-[2-9][0-9]{6,7}$/;
        var regMobile = /^((1[358][0-9])|(14[57])|(17[0678])|(19[7]))\d{8}$/;
        if (!regTel.test(inputValue) && !regMobile.test(inputValue)) {
            return {
                success: false,
                message: '请输入合法的电话号码(固定电话或者手机号)'
            }
        }
    }
    //校验邮政编码
    if (controlId == 'postcode' && inputValue) {
        var reg = /^[1-9]\d{5}$/;
        if (!reg.test(inputValue)) {
            return {
                success: false,
                message: '请输入合法的邮政编码'
            }
        }
    }

    return {
        success: true,
        message: ''
    };
}



//设置出错的input红色高亮
Widget.prototype.setContorlError = function (controlId) {
    if (!controlId)
        return;
    var inputEle = document.getElementById('input-' + controlId);
    if (!inputEle)
        return;
    inputEle.classList.add("error");
}

//跟新消息
Widget.prototype.updateMessage = function (message, isError) {
    var msgLabel = document.getElementById('message-label');
    msgLabel.innerHTML = message;
    if (isError) {
        msgLabel.classList.add('error')
    } else {
        msgLabel.classList.remove('error');
    }
}

//更新视图
Widget.prototype.updateView = function (params) {
    this.init(params);
    this.displayParams();
    this.initView();
}



function Widget(containerId, params) {
    this.containerId = containerId;
    this.init(params);
    this.initDisplayParams();
    this.initView();
}




export default Widget;