
function toDateString(date){
    if(date&&date instanceof Date)
    {
        var result='';
        result+=date.getFullYear()+'-';
        result+=(date.getMonth()+1)+'-';
        result+=date.getDate();
        return result;
    }
}

function toTimeString(date){
    if(date&&date instanceof Date)
    {
        var result='';
        var hour=date.getHours();
        if(hour<10){
            hour='0'+hour;
        }
        var minute=date.getMinutes();
        if(minute<10){
            minute='0'+minute;
        }
        var second=date.getSeconds();
        if(second<10){
            second='0'+second;
        }
        result+=hour+':';
        result+=minute+':';
        result+=second;
        return result;
    }
}

function toDatetimeString(date){
    var result="";
    var dateStr=toDateString(date);
    if(dateStr){
        result+=dateStr;
        var timeStr=toTimeString(date);
        if(timeStr){
            result+=' '+timeStr;
        }
        return result;
    }
}

function translateTimestampToString(timestamp){
    var time=new Date();
    try{
        time.setTime(timestamp);
    }
    catch(error){
        time=new Date();
    }
    return toDatetimeString(time);
}


/**
 * 按照格式化字符串来格式化
 *
 * @param {*} time
 * @param {*} formatPattern YYYY年份，MM月份，DD日期，hh小时，mm分钟，ss秒数
 * @returns
 */
function format(time,formatPattern){
    //检查formatPattern是否合法
    if(!time instanceof Date){
        throw new Error('时间参数不合法');
    }
    else if(typeof formatPattern!=='string'){
        throw new Error('时间格式化参数不合法');
    }
    else{
        return formatPattern.replace("YYYY",String(time.getYear()+1900))
            .replace("MM",time.getMonth()+1 < 10?'0'+(time.getMonth()+1):time.getMonth()+1)
            .replace("DD",time.getDate()<10?'0'+time.getDate():time.getDate())
            .replace('hh',time.getHours()?'0'+time.getHours():time.getHours())
            .replace("mm",time.getMinutes()?'0'+time.getMinutes():time.getMinutes())
            .replace("ss",time.getSeconds()?'0'+time.getSeconds():time.getSeconds());
    }
}

function getTimeFromTimestamp(timestamp){
    return new Date(timestamp);
}

export default {
    toDateString:toDateString,
    toTimeString:toTimeString,
    toDatetimeString:toDatetimeString,
    translateTimestampToString:translateTimestampToString,
    format:format
}