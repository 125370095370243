module.exports = function (obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<!-- 参数:commentlist -->\r\n<div class=\'comment-list\'>\r\n    <div class=\'comment-count\'>\r\n        <span id=\'comment-count\'>' +
((__t = (commentlist.length)) == null ? '' : __t) +
'</span>条评论\r\n    </div>\r\n    <ul>\r\n        ';
commentlist.forEach(function(comment){;
__p += '\r\n            <li class=\'comment\'>\r\n                <div>\r\n                    <div>\r\n                        <span class=\'comment-userinfo\'>' +
((__t = (comment.username)) == null ? '' : __t) +
'</span>\r\n                        <span class=\'comment-time\'>' +
((__t = (comment.time)) == null ? '' : __t) +
'</span>\r\n                    </div>\r\n                    <p class=\'comment-content\'>' +
((__t = (comment.content)) == null ? '' : __t) +
'</p>\r\n                    ';
if(comment.replycontent){;
__p += '\r\n                        <div class=\'comment-reply\'>\r\n                            <span class=\'comment-userinfo reply-user\'>管理员回复:</span>\r\n                            <span class=\'comment-content reply-content\'>' +
((__t = (comment.replycontent)) == null ? '' : __t) +
'</span>\r\n                        </div>\r\n                    ';
};
__p += '\r\n                </div>\r\n            </li>\r\n        ';
});
__p += '\r\n    </ul>\r\n</div>';

}
return __p
}