import './list.css';
import 'lib/pagination/pagination.css';

import AbstractGallery from './gallery';
import template from './template/list-typical-application.ejs';
import emptyTemplate from './template/empty.ejs';
import paginationTemplate from './template/pagination.ejs';
import 'lib/pagination/jquery.pagination';
import status from 'components/login/status';
import axios from 'axios';

class Gallery extends AbstractGallery{ 
	
    constructor(pid,containeriId,limit){
        super(pid,containeriId,limit);
        this.sortField='createtime';//保存排序的字段
        this.template=template;
    }

    request(option){
    	this.count_per_page = 4;
        option=option||{};
        if(this.keyWord){
            option.search=this.keyWord;
        }
        if(!option){
        	option['page'] = 1;
        }
        option.offset = (option.page-1)*this.count_per_page || 0
        option.limit=this.count_per_page;
        option.sort= option.sort||this.sortField;
        option.order=option.order||this.sortOrder;
        if(!this.queryParam){
            //首页展示
            option.type=2;
        }
        else{
            option.pid=this.queryParam;
        }
        return axios.get(config.urls.application_getall,{
            params:option
        }).then(function(result){
            result=result.data;
            if(result.code!==200){
                return Promise.reject('请求错误');
            }
            else{
                return result.data;
            }
        });
    };

    initPagination(total){
        const self=this;
        //初始化分页组件
        $("#pagination").pagination(total, {
            'items_per_page': this.count_per_page,
            'current_page': 0, //默认0，第一页
            'num_display_entries': 5,
            'num_edge_entries': 1,
            'prev_text': "上一页",
            'next_text': "下一页",
            'link_to': "javascript:void(0);",
            'callback': (n)=> {
                self.request({
                    page:n+1
                }).then((data)=>{
                    self.destroyGallery();
                    self.renderGallery(data.rows);
                });
            }
        });
    };  

    render(data){
        this.destroy();
        this.renderGallery(data.rows);
        this.renderPagination(data.total);
        this.initPagination(data.total)
    }

    
    renderGallery(array){
    	var imgurls = '', snapurl = '';
    	for(var i in array){
    		imgurls = '';
    		if(array[i].snapurl){
    			snapurl = array[i].snapurl;
				imgurls = config.loclUrl+'api/application/query/getApplicationsFile?fileName='+snapurl.substring(snapurl.lastIndexOf("\/") + 1, snapurl.length);
    		}
    		array[i].snapurl = imgurls;
    	}
        const div=document.createElement('div');
        let html;
        if(array.length>0){
            html= this.template({itemArray:array});
        }else{
            html=emptyTemplate();
        }

        div.innerHTML=html;
        const firstChild=this.container.firstChild;
        this.container.insertBefore(div,firstChild);
        return this;
    }

    catalogChangehandler(pid){
        this.queryParam=pid;
        this.refresh();
    }
}

export default Gallery;
