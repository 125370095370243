const Cookies=require('js-cookie');

const esriCookie= Cookies.get('esri_auth')&&JSON.parse(decodeURIComponent(Cookies.get('esri_auth')));

function getAgsToken(){
    return Cookies.get('agstoken');
}

function getOneMapToken(){
    return Cookies.get('onemaptoken');
}

function getPortalToken(){
    return esriCookie&&esriCookie.token;
}

function getUserId(){
    return Cookies.get('onemap_userid');
}

function getUserName(){
    return Cookies.get('onemap_username');
}

function getHeadersToken() { 
    return Cookies.get('onemap_token')
}

function getUserFullname(){
    return Cookies.get('onemap_fullname');
}

function getUserType(){
    return Cookies.get('onemap_usertype')||0;
}

function getRole(){
    return esriCookie&&esriCookie.role;
}

function getEsriCookie(){
    return esriCookie;
}

function getOrgId(){
    return esriCookie&&esriCookie.accountId;
}

export default{
    getAgsToken,
    getOneMapToken,
    getPortalToken,
    getUserName,
    getHeadersToken,
    getUserType,
    getRole,
    getOrgId,
    getUserId,
    getEsriCookie,
    getUserFullname,
}