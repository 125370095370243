module.exports = function (obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

var util=require("components/utils/dateUtils").default;;
__p += '\r\n\r\n<div class="" id="gallery">\r\n    ';
 itemArray.forEach(function(item){;
__p += '\r\n    <div class="item-list-style">\r\n\r\n        <div class="item-content row">\r\n            <div class="col-xs-6 col-md-3">\r\n                <a class="thumbnail" href="' +
((__t = (config.appdetailPage)) == null ? '' : __t) +
'?appid=' +
((__t = (item.applicationid)) == null ? '' : __t) +
'" target="_blank"><img src="' +
((__t = (item.snapurl)) == null ? '' : __t) +
'" alt=""></a>\r\n\r\n            </div>\r\n            <div class="col-md-9">\r\n                <div class="item-header">\r\n                    <span class="item-title">' +
((__t = (item.title)) == null ? '' : __t) +
'</span>\r\n                        <a href="' +
((__t = (config.appdetailPage)) == null ? '' : __t) +
'?appid=' +
((__t = (item.applicationid)) == null ? '' : __t) +
'" class="item-detal-entry pull-right" target="_blank">详细信息>></a>\r\n                </div>\r\n                <dl class="item-properties dl-horizontal">\r\n                    <div class="item-property-container">\r\n                            <dt>发布者：</dt>\r\n                            <dd>\r\n                                ' +
((__t = (item.author||"超级管理员")) == null ? '' : __t) +
'\r\n                            </dd>\r\n                    </div>\r\n                    <div class="item-property-container">\r\n                            <dt>发布时间：</dt>\r\n                            <dd>\r\n                                ' +
((__t = (util.format(new Date(item.createtime),"YYYY/MM/DD"))) == null ? '' : __t) +
'\r\n                            </dd>\r\n                        </div>\r\n                    <div class="item-property-container">\r\n                        <dt>摘要信息：</dt>\r\n                        <dd>\r\n                            ' +
((__t = (item.description||"暂无")) == null ? '' : __t) +
'\r\n                        </dd>\r\n                    </div>\r\n                </dl>\r\n            </div>\r\n\r\n        </div>\r\n    </div>\r\n    ';
});
__p += '\r\n</div>';

}
return __p
}