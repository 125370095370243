import './list.css';
import 'lib/pagination/pagination.css';

import AbstractGallery from './basegallery.resource';
import template_list from './template/list-resource-portalmaps.ejs';
import template_card from './template/gallery-index.ejs';

import emptyTemplate from './template/empty.ejs';
import paginationTemplate from './template/pagination.ejs';
import arcgis from 'components/portal/index';
import 'lib/pagination/jquery.pagination';
import status from 'components/login/status';
import pretreatment from './pretreatment';
import queryStrings from './portalSourceQueryString';

/**
 * 用于资源中心展示来自portal的地图资源
 *
 * @class Gallery
 * @extends {AbstractGallery}
 */
class Gallery extends AbstractGallery{

    constructor(options){
        super(options);

        if(options.portalUsers){
            this.portalUsers=options.portalUsers;
        }
    }

    prepareQueryString(){
        return this.queryParam;
    }

    renderGallery(array){
        if(this.listStyle==='list'){
            this.template=template_list;
        }
        else if(this.listStyle==='card'){
            this.template=template_card;
        }

        array.map((item)=>{
            item=pretreatment.thumbnail(item,status.getPortalToken());
            item=pretreatment.openUrl(item);
            return item;
        })

        const div=document.createElement('div');
        let html;
        if(array.length>0){
            html= this.template({itemArray:array});
        }else{
            html=emptyTemplate();
        }

        div.innerHTML=html;
        const firstChild=this.container.firstChild;
        this.container.insertBefore(div,firstChild);
        return this;
    };

    renderPagination(){
        const div=document.createElement('div');
        div.innerHTML='<div id="pagination-portalmaps" class="pagination-jq pull-right"></div>';
        this.container.appendChild(div);
        return this;
    }

    initPagination(total){
        const self=this;
        //初始化分页组件
        $("#pagination-portalmaps").pagination(total, {
            'items_per_page': this.count_per_page,
            'current_page': 0, //默认0，第一页
            'num_display_entries': 5,
            'num_edge_entries': 1,
            'prev_text': "上一页",
            'next_text': "下一页",
            'link_to': "#",
            'callback': function(n) {
                self.request({
                    page:n
                }).then((data)=>{
                    self.destroyGallery();
                    self.renderGallery(data.results);
                });
            }
        });
    };

    catalogChangehandler(queryString){
        this.queryParam=queryString;
        this.refresh();
    }
}



export default Gallery;