import './list.css';
import 'lib/pagination/pagination.css';

import AbstractGallery from './basegallery.resource';
import template_list from './template/list-resource-document.ejs';
import template_card from './template/gallery-onemap-index.ejs';

import emptyTemplate from './template/empty.ejs';
import paginationTemplate from './template/pagination.ejs';
import 'lib/pagination/jquery.pagination';
import pretreatment from './pretreatment';
import status from 'components/login/status';
import axios from 'axios';


/**
 * 用于资源中心展示来自OneMap的文档资源
 *
 * @class Gallery
 * @extends {AbstractGallery}
 */
class Gallery extends AbstractGallery{ 

    constructor(option){
        super(option);
        this.sortField='createtime';//保存排序的字段
    }

    request(option){
        option=option||{};
        if(this.keyWord){
            option.search=this.keyWord;
        }
        option.offset=option.offset||0;
        option.classid=option.classid||this.queryParam;
        option.limit=this.count_per_page;
        option.orderfield= option.orderfield||this.sortField;
        option.ordertype=option.ordertype||this.sortOrder;

        if(option.classid instanceof Array){
            option.classid=option.classid.join(',');
        }

        return axios.get(config.urls.service,{
            params:option
        }).then((result)=>{
            result=result.data;
            if(result.code!==200){
                return Promise.reject('请求错误');
            }
            else{
                this.onRequest(result.data);
                return result.data;
            }
        });
    };

    initPagination(total){
        const self=this;
        //初始化分页组件
        $("#pagination-document").pagination(total, {
            'items_per_page': this.count_per_page,
            'current_page': 0, //默认0，第一页
            'num_display_entries': 5,
            'num_edge_entries': 1,
            'prev_text': "上一页",
            'next_text': "下一页",
            'link_to': "#",
            'callback': (n)=> {
                self.request({
                    offset:n*this.count_per_page
                }).then((data)=>{
                    self.destroyGallery();
                    self.renderGallery(data.servicesInfoList);
                });
            }
        });
    };  

    renderGallery(array){
        if(this.listStyle==='list'){
            this.template=template_list;
        }
        else if(this.listStyle==='card'){
            this.template=template_card;
        }
        if(!array){
            array=[];
        }
        array.map((item)=>{
            item=pretreatment.thumbnail(item,status.getPortalToken());
            item=pretreatment.openUrl(item);
            return item;
        })

        const div=document.createElement('div');
        let html;
        if(array.length>0){
            html= this.template({itemArray:array});
        }else{
            html=emptyTemplate();
        }

        div.innerHTML=html;
        const firstChild=this.container.firstChild;
        this.container.insertBefore(div,firstChild);
        return this;
    };

    renderPagination(){
        const div=document.createElement('div');
        div.innerHTML='<div id="pagination-document" class="pagination-jq pull-right"></div>';
        this.container.appendChild(div);
        return this;
    }

    render(data){
        this.destroy();
        this.renderGallery(data.servicesInfoList);
        this.renderPagination(data.sumCount);
    }

    catalogChangehandler(classid){
        this.queryParam=classid;
        this.refresh();
    }
}


export default Gallery;