import end from '../lib/end'

export default function CreateItem (arcgis) {
  return function create ({owner, title, type, description, url, data, snippet, tags, access = 'private', licenseInfo, thumbnailURL,
   typeKeywords,extent,text,folderId,item} = {}, cb) {
    var options = {
      title: title,
      description: description,
      type: type,
      tags: tags,
      access: access,
      owner : owner,
      type : type,
      description : description,
      url : url,
      data : data,
      snippet : snippet,
      licenseInfo : licenseInfo,
      thumbnailURL : thumbnailURL,
      typeKeywords : typeKeywords,
      item : item,
      text : text,
      folderId : folderId,
      extent : extent
    }
    if (url !== undefined) { options.url = url }
    if (data !== undefined) { options.text = data }
    if (snippet !== undefined) { options.snippet = snippet }
    if (licenseInfo !== undefined) { options.licenseInfo = licenseInfo }
    if (thumbnailURL !== undefined) { options.thumbnailURL = thumbnailURL }
    if (extent !== undefined) { options.extent = extent }
    if(item!==undefined){options.item=item;}
    if(text!==undefined){options.text=text;}
    if(folderId!==undefined){options.folderId=folderId;}
    if(description!==undefined){options.description=description;}
    if(typeKeywords!==undefined){options.typeKeywords=typeKeywords;}

    var createUrl=folderId?'content/users/' + owner +"/"+folderId+ '/addItem':'content/users/' + owner + '/addItem';

    var createPromise = arcgis.request({
      url: createUrl,
      form: options,
      post: true
    })
    .then(function (confirm) {
      return arcgis.item(confirm.id)
    })

    return end(createPromise, cb)
  }
}
