import footerTemplate from './footer.ejs';
import axios from 'axios';
import './footer.css';

sth();
function sth(){
	axios.get(config.ipurl+'static/beianhao.json').then(e => {
		if(e.data.data[0].beianhao){
			$('#bah').show();
			if(e.data.data[0].beianhaoUrl){
				$('#bah').html('<a href="'+e.data.data[0].beianhaoUrl+'" target="_blank">'+e.data.data[0].beianhao+'</a>')
			}else{
				$('#bah').html(e.data.data[0].beianhao)
			}
		}
		if(e.data.data[0].shentuhao){
			$('#sth').show()
			if(e.data.data[0].shentuhaoUrl){
				$('#sth').html('<a href="'+e.data.data[0].shentuhaoUrl+'" target="_blank">'+e.data.data[0].shentuhao+'</a>')
			}else{
				$('#sth').html(e.data.data[0].shentuhao)
			}
		}
		if(e.data.data[0].gongwanganhao){
			$('#gzh').show()
			if(e.data.data[0].gongwanganhaoUrl){
				$('#gzh').html('<a href="'+e.data.data[0].gongwanganhaoUrl+'" target="_blank">'+e.data.data[0].gongwanganhao+'</a>')
			}else{
				$('#gzh').html(e.data.data[0].gongwanganhao)
			}
		}
	})
}

function renderFooter(){
	var html=footerTemplate({count:1});
	const container=document.createElement('div');
        container.innerHTML=html;
        document.getElementById('footer').appendChild(container);
   	return container;
   /* return getVisitedCount().then((count)=>{
        var html=footerTemplate({count:count});
        const container=document.createElement('div');
        container.innerHTML=html;
        document.getElementById('footer').appendChild(container);
        return container;
    })*/

}

function addVisitedCount(){
    return axios.get(config.urls.tpanalyticslog+'/addCount');
}

function getVisitedCount(){
    return addVisitedCount().then(()=>{
        return axios.get(config.urls.tpanalyticslog+'/getCount').then((reuslt)=>{
            return reuslt.data.data;
        });
    });
}

/*党政机关识别码*/
// document.write(unescape("%3Cspan id='_ideConac' %3E%3C/span%3E%3Cscript src='http://dcs.conac.cn/js/16/234/0000/60229436/CA162340000602294360004.js' type='text/javascript'%3E%3C/script%3E"));

export {renderFooter};
