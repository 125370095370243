import './style.css';

class Search{
    constructor(id,onSearch){
        if(!id){
            throw new Error('搜索组件需要提供dom容器id');
            return null;
        }
        this._keyword='';
        this.dom=document.getElementById(id);
        this._onSearch=onSearch;
        this.bind();
    }

    get keyword(){return this._keyword;}
    set keyword(value){this._keyword=value;this.handleKeywordsChange();}
    set onSearch(value){this._onSearch=value;}
    get onSearch(){return this._onSearch;}

    bind(){
        const searchInput=this.dom.querySelector('input.search-text');
        const searchButton=this.dom.querySelector('button.search-btn');
        searchInput.addEventListener('keydown',(e)=>{
            const key=e.keyCode;
            if(key===13){
                const keyword=searchInput.value.trim();
                this.keyword=keyword;
                this.handleKeywordsChange();
            }
        });

        searchButton.addEventListener('click',(e)=>{
            const keyword=searchInput.value.trim();
            this.keyword=keyword;
        });
    }

    reset(){
        const searchInput=this.dom.querySelector('input.search-text');
        searchInput.value='';
    }

    handleKeywordsChange(){
        this.onSearch(this.keyword);
    }
}

export default Search;