import './style.css';

const DEFAULT_ORDER='desc';

class Sort{
    constructor(id,onFieldChange,onOrderChange) {
        if(!id){
            throw new Error('排序组件需要提供dom容器id');
            return null;
        }

        this.dom = document.getElementById(id);
        if(!this.dom){
            throw new Error('找不到排序组件');
        }

        this._currentOrder = DEFAULT_ORDER;
        this._currentField = '';
        this.dom=document.getElementById(id);
        this._onOrderChange=onOrderChange;
        this._onFieldChange=onFieldChange;
        this.init().bind();
    }

    set order(value) {this._currentOrder = value;this.handleOrderChange();this.onOrderChange(this.field,this.order);}
    get order() {return this._currentOrder;}
    set field(value) {this._currentField = value;this.handleFieldChange();this.onFieldChange(this.field,this.order);}
    get field() {return this._currentField;}
    set onFieldChange(value){this._onFieldChange=value;}
    get onFieldChange(){return this._onFieldChange||this.noop;}
    set onOrderChange(value){this._onOrderChange=value;}
    get onOrderChange(){return this._onOrderChange||this.noop;}

    init() {
        
        const firstField = this.dom.querySelector('a.sort-content');
        this.field=firstField.dataset.sort;
        this.order=DEFAULT_ORDER;
        return this;
    }

    reset(){
        const firstField = this.dom.querySelector('a.sort-content');
        this._currentField=firstField.dataset.sort;
        this._currentOrder=DEFAULT_ORDER;
        this.handleOrderChange();
        this.handleFieldChange();
    }

    bind() {
        this.dom.addEventListener("click", (event) => {
            const target = event.target;
            if (target.tagName.toLowerCase() == "a") {
                const field = target.getAttribute("data-sort");
                if (field === this.field) {
                    this.order=this.convertOrder(this.order);
                } else {
                    this.field=field;
                }
            }
            else{
                return;
            }
        });
    }


    /**
     * 排序方式改变处理
     *
     * @memberof Sort
     */
    handleOrderChange() {
        const selectedFieldOrder=this.dom.querySelector('a.active span');
        if(selectedFieldOrder){
            selectedFieldOrder.className=this.order;
        }
    }


    /**
     * 排序字段变化处理
     *
     * @memberof Sort
     */
    handleFieldChange(){
        const oldSelectedFiled=this.dom.querySelector('a.active');
        if(oldSelectedFiled){
            oldSelectedFiled.className=oldSelectedFiled.className.replace(' active','').trim();//把选中的排序字段的样式清除
            oldSelectedFiled.lastElementChild.className='';//去除排序符号
        }

        const selectedField= this.dom.querySelector('a[data-sort='+this.field+']');
        selectedField.className+=' active';//新选中的字段添加样式
        if(selectedField.lastElementChild.tagName.toLocaleLowerCase()==='span'){
            selectedField.lastElementChild.className=this.order;//如果没有排序符号则加上排序符号
        }
    }

    convertOrder(order) {
        if (order === "desc") {
            return "asc"
        } else {
            return "desc"
        }
    }


    noop(){
        //空操作
    }
}

export default Sort;