import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import pagenation from './custom-pagenation.ejs';
import './custom-pagenation.css';

class module{
    constructor(name,pageCount,capacity,containerid,onPageChanged,currentPageNum){
        //分页控件名称
        this.name=name;
        //当前页码(从1开始)
        this.currentPageNum=currentPageNum?currentPageNum:1;
        //总页数
        this.pageCount=pageCount;
        //页码起点
        this.pageNumStart=1;
        //每页条数
        this.capacity=capacity;
        //html父节点id
        this.containerid=containerid;
        //页码变化时的响应函数
        this.onPageChanged=onPageChanged;
    }


    initView(){
        if(this.pageCount==0)
            return;
        var pagelist=[];
        if(this.pageCount<10)
        {
            for(var i=0;i<this.pageCount;i++){
                pagelist.push(i+1);
            }
        }
        else{
            for(var i=0;i<10;i++){
                pagelist.push(i+1);
            }
        }
        this.updateView(pagelist);
    }

    updateView(pagelist){
        var html=pagenation({pagelist:pagelist,name:this.name});
        //将分页栏加入页面中
        var container=document.getElementById(this.containerid);
        //如果container中已经有分页控件了，注销事件
        if(container.getElementsByTagName('ul')&&container.getElementsByTagName('ul').length>0){
            container.getElementsByTagName('ul')[0].onclick=null;
        }
        container.innerHTML=html;
        container.classList.remove('invisible');
        if(pagelist.length==1){
            container.classList.add('invisible');
        }
        if(this.currentPageNum){
            var activeBtn=document.getElementById(this.name+'_pageBtn_'+this.currentPageNum);
            if(activeBtn){
                activeBtn.classList.add('active');
            }
        }
        var that=this;
        //注册事件
        if(container){
            container.getElementsByTagName('ul')[0].onclick=function(event){
                event.preventDefault();
                that.changeCurrentPage(event);
            };
        }
    }

    setPagenum(pagenum){
        if(!pagenum||isNaN(pagenum)||pagenum>this.pageCount||pagenum<1)
            return;
        this.currentPageNum=pagenum;
        this.triggerEvent();
    }


    changeCurrentPage(event){
        var target=event.target;
        var pagenum;    
        //根据点击的按钮获取页码
        if(target.tagName=='A'){   
        }
        else{
            //说明点击的是上一页或者下一页
            target=target.parentNode;
        }
        pagenum=target.getAttribute('data-pagenum');
        switch(pagenum){
            case 'previous':{
                    //如果是上一页的话，判断是否为第一页，如果不是第一页，当前页面递减
                if(this.currentPageNum!=1){
                    this.currentPageNum--;
                }
                break;
            }
            case 'next':{
                //如果是下一页，判断是否为最后一页，如果不是最后一页，当前页面递增
                if(this.currentPageNum<this.pageCount){
                    this.currentPageNum++;
                }
                break;
            }
            default:{
                //直接将选中的页码设为当前页
                if(pagenum!=this.currentPageNum){
                    this.currentPageNum=Number(pagenum);
                }
                break;
            }
        }
        //如果总页数大于10，且当前选中页码处于分页条的后半段时(currentPageNum-pageNumStart>=5)时
        //整个分页条的页码数需要左移(并尽量保证当前选中页码在最中间)
        if(this.pageCount>10&&this.currentPageNum-this.pageNumStart>4){
            var pagelist=[];
            var start,end;
            if(this.currentPageNum+3<this.pageCount){
                //当前选中页码后边还有足够的页码可用，此时将当前选中项放在10个页码中的第6位
                start=this.currentPageNum-5;
                end=this.currentPageNum+4;              
            }
            else{
                //当前选中项页码后面没有足够的页码可以用了，则取页码总数中取最大的十个
                start=this.pageCount-9;
                end=this.pageCount;
            }
            for(var i=start;i<end+1;i++){
                pagelist.push(i);
            }
            //用新的页码去构造分页条
            this.updateView(pagelist);
        }
        this.setActiveBtn();
        this.triggerEvent();
    }

    setActiveBtn(){
        var container=document.getElementById(this.containerid);
        if(container){
            var alist=container.getElementsByTagName('a');
            for(var i=0;i<alist.length;i++){
                var aEle=alist[i];
                aEle.classList.remove('active');
                if(aEle.getAttribute('data-pagenum')===this.currentPageNum.toString()){
                    aEle.classList.add('active');
                }
            }
        }
    }


    triggerEvent(){
        //构造分页信息
        var pageInfo={
            limit:this.capacity,
            offset:(this.currentPageNum-1)*this.capacity
        }
        if(this.onPageChanged!=null){
            this.onPageChanged(pageInfo);
        }
    }

}

export default module;